import { upperFirst, camelCase } from "lodash";

// Automatic register base/icon components
export const registerBaseComponents = function (app) {
  const requireComponent = require.context(
    "./base/",
    false,
    /Base[A-Z]\w+\.(vue|js)$/
  );
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(
      camelCase(
        fileName
          .split("/")
          .pop()
          .replace(/\.\w+$/, "")
      )
    );
    app.component(componentName, componentConfig.default || componentConfig);
  });
};
