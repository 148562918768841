<template>
  <div class="input-group">
    <fa-icon icon="search" class="search-icon"></fa-icon>
    <input
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
    />
    <fa-icon
      class="icon"
      icon="times-circle"
      v-show="modelValue"
      @click="$emit('update:modelValue', '')"
    ></fa-icon>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/_form.scss";

.search-icon {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
}
input {
  padding-left: 2rem !important;
}
</style>
