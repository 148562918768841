import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBnaF9vSNtHvIdXh4KR30HUPTxfmN3WeTM",
  authDomain: "application-inventaires.firebaseapp.com",
  // databaseURL: "https://6sb2gu992w4b.europe-west1.firebasedatabase.app",
  databaseURL:
    "https://app-inventaires-easilys.europe-west1.firebasedatabase.app",
  projectId: "application-inventaires",
  storageBucket: "application-inventaires.appspot.com",
  messagingSenderId: "192846224574",
  appId: "1:192846224574:web:c58657bd321a7020bf92ef",
  measurementId: "G-W7QXGTC6VB",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init functions
const projectFunctions = firebase.functions();

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectDatabase = firebase.database();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const serverValue = firebase.database.ServerValue;
const authPersistence = firebase.auth.Auth.Persistence;

// Emulators
// if (process.env.NODE_ENV !== "production") {
//   firebase.functions().useEmulator("localhost", 5001);
//   firebase.database().useEmulator("localhost", 9000);
//   firebase
//     .auth()
//     .useEmulator("http://localhost:9099", { disableWarnings: true });
//   firebase.firestore().useEmulator("localhost", 8090);
// }

export {
  firebaseConfig,
  projectFirestore,
  projectAuth,
  projectDatabase,
  projectFunctions,
  timestamp,
  arrayUnion,
  serverValue,
  authPersistence,
};
