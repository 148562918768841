<template>
  <div class="page-header" :style="noPadding ? 'padding: 0' : 'padding: 1rem'">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  position: sticky;
  display: block;
  top: 0;
  background-color: white;
  min-width: 100%;
  border-bottom: solid 1px #f0f0f0;
  z-index: 1;
}
</style>
